<template>
  <v-container
    fill-height
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col 
        cols="12"
        class="d-flex justify-center"
      >
        <v-spacer></v-spacer>
        <h1>Booking</h1>
        <v-spacer></v-spacer>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'Booking',
  components: {

  },
  props: {

  },
  data: () => ({

  }),
  methods: {

  },
  mounted () {

  }
}
</script>